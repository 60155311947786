import { render, staticRenderFns } from "./controllSeason.vue?vue&type=template&id=64546f55&scoped=true"
import script from "./controllSeason.vue?vue&type=script&lang=js"
export * from "./controllSeason.vue?vue&type=script&lang=js"
import style0 from "./controllSeason.vue?vue&type=style&index=0&id=64546f55&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64546f55",
  null
  
)

export default component.exports