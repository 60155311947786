<!--设备模块--季节切换控制-->
<template>
  <div id="controllSeason" v-loading="loading" :element-loading-text="$t('controllseason.a9')">
    <!-- 页面标题 -->
    <my-PageTitle>{{ $t('controllseason.a10') }}</my-PageTitle>
    <!-- 内容区域 -->
    <div class="content">
      <!-- 侧边导航栏 -->
      <div class="aside">
        <my-navder :list="asideList" @select="getMsgFormSon"></my-navder>
      </div>
      <!-- 数据显示区域 -->
      <div class="main" v-if="id">
        <!-- 名称栏 -->
        <div class="node_name">
          <div>
            <span>{{ nodeParentName }}：</span>
            <span>{{ nodeName }}</span>
            <a-icon type="form" :style="{ fontSize: '15px', color: '#7682CE', 'margin-left': '15px'}" @click="openNameUpdata"/>
          </div>
          <div>
            <span>{{$t('controllseason.a2')}}</span>
            <span>{{ nodeId }}</span>
          </div>
        </div>
        <!-- 表格 -->
        <div class="content_table">
          <my-tabletitle>{{ $t("controllseason.a3") }}</my-tabletitle>
          <my-outCtrlTable :data="analogList"></my-outCtrlTable>
          <my-tabletitle>{{ $t("controllseason.a4") }}</my-tabletitle>
          <my-outCtrlTable :data="boundList"></my-outCtrlTable>
          <my-tabletitle>{{ $t("controllseason.a5") }}</my-tabletitle>
          <my-outCtrlTable :data="runModeList"></my-outCtrlTable>
        </div>
        <!-- 底栏 -->
        <div class="footer">
          <a-button type="primary" @click="openSeasonalSetting" style="margin-right: 15px;">{{ $t("controllseason.a7") }}</a-button>
          <a-button type="primary" @click="parAndRunmode">{{ $t("controllseason.a8") }}</a-button>
        </div>
      </div>
      <!-- 空数据 -->
      <div class="empty" v-else>
        <a-empty />
      </div>
    </div>

    <!-- 名称修改对话框 -->
    <a-modal :title="$t('controllseason.a11')" :width="300" v-model="nameUpdataVisible" centered v-drag :destroyOnClose="true">
      <template slot="footer">
        <a-button key="back" @click="NameUpdataCancel">{{ $t('controllseason.a12') }}</a-button>
        <a-button key="submit" type="primary" @click="nameUpdate" :disabled="nameUpdataDisabled">{{ $t('controllseason.a13') }}</a-button>
      </template>
      <div class="name_content" v-loading="nameUpdataLoading" :element-loading-text="$t('controllseason.a9')">
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
          <a-form-model-item ref="nodeNameUpdata" :label="$t('controllseason.a14')" prop="nodeNameUpdata">
            <a-input v-model="form.nodeNameUpdata" allowClear @blur="() => { $refs.name.onFieldBlur() }" :placeholder="$t('controllseason.a15')"/>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>

    <!-- 季节设置对话框 -->
    <a-modal title="季节设置" :width="700" v-model="seasonalSettingVisible" centered v-drag :destroyOnClose="true">
      <template slot="footer">
        <a-button key="back" @click="seasonalSettingCancel">{{ $t('controllseason.a12') }}</a-button>
        <a-button key="submit" type="primary" @click="seasonalSetting" :disabled="seasonalSetting_ButDisabled">{{ $t('controllseason.a13') }}</a-button>
      </template>
      <div class="seasonalSetting_content" v-loading="seasonalSettingLoading" :element-loading-text="$t('controllseason.a9')">
        <!-- 夏季模式 -->
        <div class="one_row">
          <div>
            <span>{{$t("controllseason.b2")}}</span>
          </div>
          <div>
            <a-select class="select" v-model="seasonInfo.summerstartmonth"> 
              <a-select-option v-for="item in months" :key="item" :value="item">
                {{item}}
              </a-select-option>
            </a-select> 
            <span>{{$t("controllseason.b3")}}</span>
            <a-select class="select" v-model="seasonInfo.summerstartday">
              <a-select-option v-for="item in days" :key="item">
                {{item}}
              </a-select-option>                  
            </a-select> 
            <span>{{$t("controllseason.b4")}}  ~  </span>
            <a-select class="select" v-model="seasonInfo.summerendmonth">
              <a-select-option v-for="item in months" :key="item">
                {{item}}
              </a-select-option>
            </a-select>
            <span>{{$t("controllseason.b3")}}</span>
            <a-select class="select" v-model="seasonInfo.summerendday">
              <a-select-option v-for="item in days" :key="item">
                {{item}}
              </a-select-option>
            </a-select> 
            <span>{{$t("controllseason.b4")}}</span>
          </div>
        </div>
        <!-- 冬季模式 -->
        <div class="row">
          <div>
            <span>{{$t("controllseason.b5")}}</span>
          </div>
          <div>
            <a-select class="select" v-model="seasonInfo.winterstartmonth"> 
              <a-select-option v-for="item in months" :key="item" :value="item">
                {{item}}
              </a-select-option>
            </a-select> 
            <span>{{$t("controllseason.b3")}}</span>
            <a-select class="select" v-model="seasonInfo.winterstartday">
              <a-select-option v-for="item in days" :key="item">
                {{item}}
              </a-select-option>                  
            </a-select> 
            <span>{{$t("controllseason.b4")}}  ~  </span>
            <a-select class="select" v-model="seasonInfo.winterendmonth">
              <a-select-option v-for="item in months" :key="item">
                {{item}}
              </a-select-option>
            </a-select>
            <span>{{$t("controllseason.b3")}}</span>
            <a-select class="select" v-model="seasonInfo.winterendday">                  
              <a-select-option v-for="item in days" :key="item">
                {{item}}
              </a-select-option>
            </a-select> 
            <span>{{$t("controllseason.b4")}}</span>
          </div>
        </div>
        <!-- 其它季节 -->
        <div class="row">
          <div>
            <span>{{$t("controllseason.b6")}}</span>
          </div>
          <div>
            <span>{{$t("controllseason.b7")}}</span>
          </div>
        </div>
        <!-- 临时变更 -->
        <div class="row">
          <div>
            <span>{{$t("controllseason.b8")}}</span>
          </div>
          <div>
            <span>{{$t("controllseason.b9")}}</span>
            <a-select class="selectRunMode" v-model="seasonInfo.runmode">
              <a-select-option v-for="item in runModes" :key="item">
                {{item}}
              </a-select-option>
            </a-select>
            <span>{{$t("controllseason.b10")}}</span>
          </div>
        </div>
      </div>
    </a-modal>

  </div>
</template>

<script>
import PageTitle from '../../components/Title/PageTitle'
import tabletitle from "../../components/Title/tabletitle";
import navdar from "../../components/Navdar/vertical";
import headerTitle from "../../components/Title/headerTitle";
import outCtrlTable from "./outCtrlTable";
import nameUpdate from "../../components/Name/nameUpdate";
import { lightTimetableListKT, getSeasonCtrlInfo, updateScheduleName, getAirConditionSeasonInfo, updateAirConditionSeasonInfo } from "../../api/device";
import select from "../../components/Select/select";

export default {
  data() {
    return {
      loading: false,
      asideList: [],
      id: undefined,//包含类型和时间表ID
      schType: 9,
      nodeId: undefined,//树节点ID
      nodeName: undefined,//树节点名称
      nodeParentName: undefined,//父节点名称
      nodeParentId: undefined,
      form: { nodeNameUpdata: '' },
      analogList:[],
      boundList:[],
      runModeList:[],
      linkageList:[],
      nameUpdataVisible: false,
      nameUpdataLoading: false,
      nameUpdataDisabled: false,
      rules: {
        nodeNameUpdata: [
          { required: true, message: this.$t('controllseason.a16'), trigger: 'blur' },
        ],
      },
      seasonalSettingVisible: false,
      seasonalSettingLoading: false,
      months:['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      days:['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
      runModes:[
        this.$t("controllseason.b12"),
        this.$t("controllseason.b13"),
        this.$t("controllseason.b14"),
        this.$t("controllseason.b15"),
      ],
      seasonInfo: {
        runmode: '',
        summerendday: '',
        summerendmonth: '',
        summerstartday: '',
        summerstartmonth: '',
        winterendday: '',
        winterendmonth: '',
        winterstartday: '',
        winterstartmonth: ''
      },
      seasonalSetting_ButDisabled: false
    }
  },
  mounted() {
    // 进入页面时调用获取页面左侧列表方法
    this.navderList() 
  },
  methods: {
    // 获取页面左侧列表方法
    navderList() {
      let name = this.$route.name;
      // console.log("controllSeason route  name",name,name.indexOf('FAC_KISETU'));
      this.schType = undefined;
      // if(name=="VRFAC_FAC_KISETU" || name=="LIGHTING_FAC_KISETU"||name=="ENVIRONMENT_FAC_KISETU"||name=="FACILITIES_FAC_KISETU")
      if(name.indexOf('FAC_KISETU')>=0)
        this.schType = 8;

      if(!this.schType){
          return;
      }
      let par = { siteId: this.$route.query.id, schType: this.schType,
      }
      // console.log("controllSeason lightTimetableListKT  par",par);  
      this.loading = true;
      lightTimetableListKT(par)
        .then((res) => {
          let { data } = res;
          // console.log("controllSeason lightTimetableListKT  data：",data);  
          data.key = "sub1";
          this.asideList.length = 0
          this.asideList.push(data)
          this.loading = false     
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 点击侧边栏获取页面详细数据加载id
    getMsgFormSon(data) {
      this.id = data
      const nodeId = this.id.split(",")[1]
      this.nodeId = nodeId
      this.menuRecursion(this.asideList)
      const par = { siteId: this.$route.query.id, seasonCtrlId: nodeId }  
      this.getdata(par)
    },
    // 侧边栏数据查找
    menuRecursion(treeData, nodeParent){
      const key = this.id
      for(let i = 0; i < treeData.length; i++){
        if(treeData[i].key === key){
          this.nodeName = treeData[i].title
          this.nodeParentName = nodeParent.title
          this.nodeParentId = nodeParent.key
        }
        if(treeData[i].children){ this.menuRecursion(treeData[i].children, treeData[i]) }
      }
    },
    // 加载页面数据的方法    
    getdata(par) {
      this.loading = true;      
      getSeasonCtrlInfo(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          // console.log("getSeasonCtrlInfo res",data);
          this.analogList = data.analogList.map((item,index) => { item.key = index + 1; return item;});
          this.boundList=data.boundList.map((item,index) => { item.key = index + 1; return item;});
          this.runModeList=data.runModeList.map((item,index) => { item.key = index + 1; return item;});
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },    
    // 打开名称修改对话框
    openNameUpdata(){
      this.nameUpdataVisible = true
      this.form.nodeNameUpdata = this.nodeName
    },
    // 关闭名称修改对话框
    NameUpdataCancel(){
      this.nameUpdataVisible = false
    }, 
    // 更新名称
    nameUpdate() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const asideList = this.asideList
          const newName = this.form.nodeNameUpdata
          const nodeParentId = this.nodeParentId
          const par = { scheId: this.nodeId, scheType: this.schType, newName }
          if(!this.duplicateNameChecking(asideList, nodeParentId, newName)){
            this.nameUpdataLoading = true
            this.nameUpdataDisabled = true
            // console.log("nameUpdate par:",par)
            updateScheduleName(par)
              .then((res) => {
                // console.log(res)
                if(res.errorCode === '00'){
                  this.nodeName = newName
                  this.treeNodeNameUpdata(this.asideList)
                  this.$message.success(res.msg)
                  this.nameUpdataVisible = false
                }else{
                  this.$message.error(res.msg)
                }
                this.nameUpdataLoading = false
                this.nameUpdataDisabled = false
              })
              .catch((err) => {
                console.log(err)
                this.nameUpdataLoading = false
                this.nameUpdataDisabled = false
              });
          }else{
            this.$message.error(this.$t('controlllinkage.a9'))
          };
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重名检查--特定树形结构数据，同一父级下的子节点不可重名
    duplicateNameChecking(treeData, parentKey, title) {
      let isExist = false;
      const treeRecursion = (nodes, nodeParentKey) => {
        for(let i = 0; i < nodes.length; i++){
          if(parentKey === nodeParentKey && title === nodes[i].title){ 
            isExist = true;
            return;
          }
          if(nodes[i].children){ treeRecursion(nodes[i].children, nodes[i].key) };
        }
      }
      treeRecursion(treeData);
      return isExist;
    },
    // 名称修改请求成功后侧边名称修改
    treeNodeNameUpdata(treeData){
      treeData.forEach((item) => {
        if(item.key === this.id){ item.title = this.form.nodeNameUpdata }
        if(item.children){ this.treeNodeNameUpdata(item.children) }
      })
    },
    // 打开季节设置对话框
    openSeasonalSetting(){
      this.seasonalSettingVisible = true;
      this.seasonalSettingLoading = true;
      const par = { seasonCtrlId: this.nodeId };
      getAirConditionSeasonInfo(par)
        .then((res) => {
          const { data } = res;
          this.seasonInfo = data;  
          // console.log("seasonInfoEditor SeasonInfo:",this.seasonInfo);
          this.seasonalSettingLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.seasonalSettingLoading = false;
        });
    },
    // 关闭季节设置对话框
    seasonalSettingCancel(){
      this.seasonalSettingVisible = false
    },
    // 季节设置
    seasonalSetting(){
      this.seasonalSettingLoading = true;
      this.seasonalSetting_ButDisabled = true;
      const seasonInfo = this.seasonInfo;
      updateAirConditionSeasonInfo(seasonInfo)
      .then((res) => {
        // console.log(res);
        if (res.errorCode == "00") {
          this.$message.success(res.msg);
          this.seasonalSettingVisible = false;
        } else if (res.errorCode == "02") {
          this.$message.error(res.msg);
        }
        this.seasonalSettingLoading = false;
        this.seasonalSetting_ButDisabled = false;
      })
      .catch((err) => {
        this.seasonalSettingLoading = false;
        this.seasonalSetting_ButDisabled = false;
        console.log(err);
      });
    },
    //跳转至参数和运行模式设置页
    parAndRunmode() {
      let singnalType = 3;
      this.$router.push({
        path: "/homepage/air-condition/runmodeSeason",
        query: { id: this.$route.query.id, nodeId: this.nodeId,schType:this.schType,type:singnalType,
                nodeName:this.nodeName },
      });
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-navder": navdar,
    "my-headertitle": headerTitle,
    "my-outCtrlTable":outCtrlTable,
    "my-nameupdate": nameUpdate,
    "my-select": select,
    'my-PageTitle': PageTitle
  },
};
</script>
<style scoped>
#controllSeason {
  width: 100%;
  height: 100%;
}
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.content{
  display: flex;
  height: calc(100% - 50px);
  padding-top: 15px;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
}
.main {
  width: calc(100% - 250px);
  padding: 0px 0px 0px 15px;
}
.node_name{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  font-size: 14px;
  padding-bottom: 10px;
}
.content_table{
  height: calc(100% - 85px);
  padding-right: 20px;
  overflow: auto;
}
.empty{
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 250px);
  height: 100%;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
.name_content{
  height: 100px;
}
.seasonalSetting_content{
  height: 250px;
}
.one_row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.select{
  width: 60px;
  margin: 0px 10px;
}
.selectRunMode{
  width: 110px;
  margin: 0px 10px;
} 
</style>